<script>
  import Group from "$lib/admin/components/Group.svelte";
  import SunEditorForm from "$lib/admin/components/content/SunEditorForm.svelte";
  import Block from "$lib/admin/components/blocks/helpers/Block.svelte";
  import { page } from "$app/stores";
  import { isTournamentsRoute } from "$lib/services/utils";
  import { project } from "$lib/services/store";

  export let props = null;
  export let adminView = false;

  const tournamentRoute = $project.settings.tournamentUrl ?? "tournaments";
</script>

{#if adminView}
  <Group addClass="form-item">
    {#if props}
      <SunEditorForm
        bind:text={props.content}
        placeholder="Write some great content"
        on:keyup
      />
    {/if}
  </Group>
{:else if isTournamentsRoute($page.url.pathname, tournamentRoute)}
  {#if props && props.content}
    <div
      class:dark-mode={isTournamentsRoute($page.url.pathname, tournamentRoute)}
    >
      <div class="container">
        {@html props.content}
      </div>
    </div>
  {:else}
    <p class="empty-content">
      No content written yet. Write some text to get started!
    </p>
  {/if}
{:else}
  <Block
    blockClass={"block-image" +
      (props.imageBlockType ? " " + props.imageBlockType : "")}
    gridColumn={"g-col-12 g-start-1 g-col-md-10 g-start-md-2"}
    {props}
  >
    {#if props && props.content}
      {@html props.content}
    {:else}
      <p class="empty-content">
        No content written yet. Write some text to get started!
      </p>
    {/if}
  </Block>
{/if}

<style>
  :global(.dark-mode p span[style]) {
    color: #fff !important;
    background: none !important;
  }
</style>
